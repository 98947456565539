<template>
  <div class="wrapper">
    <el-button class="button" type="text" @click="goBack" icon="el-icon-back" style="font-size:16px;">返回</el-button>
    <el-tabs v-model="tabCurIndex" class="types-tab" @tab-click="tabChange">
      <el-tab-pane v-for="(item) in tabBar" :key="item.id" :data-id="item.id" :name="item.method" :label="item.method">
        <template v-if="method == '自评'">
          <el-table v-loading="loading" :data="oneselfTableList" border :header-cell-style="{ background: '#eff3ff', color: '#5799f4' }" >
            <el-table-column type="index" width="80" label="序号" align="center"></el-table-column>
            <el-table-column prop="ratabledName" label="被评人" align="center"></el-table-column>
            <!-- <el-table-column prop="time" label="评价时间" align="center"></el-table-column> -->
            <el-table-column prop label="评价状态" align="center">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status == 0" type="warning">未评价</el-tag>
                <el-tag v-if="scope.row.status == 1" type="success">已评价</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="score" label="评价得分" align="center"></el-table-column>
            <el-table-column prop="opt" label="操作" align="center">
              <template slot-scope="scope">
                <el-button @click="toDetail(scope.row, item.method)" type="text" size="medium">{{scope.row.status == 0?'进行评价':'查看详情' }}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template> 
        <template v-if="method == '互评'">
          <el-form class="search" :inline="true" :model="query">
            <el-form-item label="评价状态:">
              <el-select v-model="query.status" clearable filterable class="select">
                <el-option v-for="item in statusOpt" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="学生姓名:">
              <el-input class="input" placeholder="输入教师姓名" v-model.trim="query.ratabledName" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchHandle" class="button">查询</el-button>
            </el-form-item>
          </el-form>
          <el-table v-loading="loading" border :data="otherTableList" :header-cell-style="{ background: '#eff3ff', color: '#5799f4' }" >
            <el-table-column type="index" width="80" label="序号" align="center"></el-table-column>
            <el-table-column prop="ratabledName" label="被评人" align="center"></el-table-column>
            <!-- <el-table-column prop="time" label="评价时间" align="center"></el-table-column> -->
            <el-table-column prop label="评价状态" align="center">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status == 0" type="warning">未评价</el-tag>
                <el-tag v-if="scope.row.status == 1" type="success">已评价</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="score" label="评价得分" align="center"></el-table-column>
            <el-table-column prop="opt" label="操作" align="center">
              <template slot-scope="scope">
                <el-button @click="toDetail(scope.row, item.method)" type="text" size="medium">{{scope.row.status == 0?'进行评价':'查看详情' }}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      query: {
        paperId: '',
        weightId: '',
        ratabledName: '',
        status: '',
      },
      statusOpt: [
        {
          id: 0,
          name: '未完成',
        },
        {
          id: 1,
          name: '完成',
        },
      ],
      tabCurIndex: '',
      tabBar: [],
      // ratabledId
      oneselfTableList: [],
      otherTableList: [],
      loading: false,
      nextArr: [],
      method: '自评',
    };
  },
  filters: {},
  mounted() {
    this.getType();
  },
  methods: {
    getType() {
      this.$get(`/evas/paper/student/weightListByPaperId/${this.$route.query.paperId}`).then(res => {
        this.tabBar = res.result;
        this.query.paperId = res.result[0].paperId;
        this.query.weightId = res.result[0].id;
        this.tabCurIndex = this.tabBar[0].method
        
        if(this.$route.query.method){
          this.method = this.$route.query.method;
          this.tabCurIndex = this.$route.query.method;
          if(this.method == "互评"){
            this.query.weightId = res.result[1].id;
          }
        }

        this.getTable();
      });
    },
    getTable() {
      this.$get('/evas/paper/student/ratabledListByType', this.query).then(res => {
        if (this.tabCurIndex == "自评") {
          this.oneselfTableList = res.result;
          this.nextArr = []
        } else if (this.tabCurIndex == "互评") {
          this.otherTableList = res.result;
          this.otherTableList.forEach(item => {
            if (item.status == 0) {
              this.nextArr.push({
                ratabledId: item.ratabledId,
                ratabledName: item.ratabledName,
              });
            }
          });
        }
      });
    },
    tabChange(e) {
      this.query.weightId = e.$attrs['data-id'];
      this.method = this.tabCurIndex
      if (e.index == 1) {
        this.query.ratabledName = '';
        this.query.status = '';
      }
      this.getTable();
    },
    searchHandle() {
      this.getTable();
    },
    goBack() {
      // this.$router.go(-1)
      this.$router.push({
        name: 'studentEvaluateIndex',
      });
    },
    toDetail(row, method) {
      let arr = this.nextArr.filter(item => {
        return row.ratabledId != item.ratabledId;
      });
      console.log(method,'method----')
      this.$router.push({
        name: 'studentEvaluateDetail',
        query: {
          paperId: this.query.paperId,
          ratabledId: row.ratabledId,
          weightId: this.query.weightId,
          method: method,
          nextArr: JSON.stringify(arr),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 800px;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.types-tab {
  /deep/ .el-tabs__item {
    font-size: 16px;
    font-weight: 600;
  }
}
.el-pagination {
  text-align: right;
  margin: 20px 0;
}
</style>